import React, { useState } from "react"

import { Formik, Form } from "formik"
import * as Yup from "yup"
import { gql, useMutation } from "@apollo/client"

import { Button } from "components/anti/buttons/buttons"
import { Input } from "components/anti/forms/forms"
import { useScrollAnim } from "src/components/hooks/hooks"

import STREAMWORX, { sendOTPQuery } from "components/utils/streamworx"
import circle from "assets/img/roll-animate.svg"

export const LoginPhone = ({ setCurrentNumber, setOtpState }) => {
  const [trigger, anim] = useScrollAnim()
  const [errMsg, setRrrMsg] = useState("")
  const [disabledBtn, setDisabledBtn] = useState(false)

  const phoneRegex = /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/

  //form requirements
  const initialRegisterValues = {
    phoneNumber: "",
  }

  const YupRegisterValidation = Yup.object({
    phoneNumber: Yup.string()
      .required("Masukkan no. HP anda")
      .matches(phoneRegex, "No. HP tidak valid"),
  })

  //Handle from formik
  const [submitOTP] = useMutation(sendOTPQuery, STREAMWORX())
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setDisabledBtn(true)
    submitOTP({
      variables: { ...values },
    })
      .then(res => {
        setCurrentNumber(values.phoneNumber)
        setOtpState(true)
        setDisabledBtn(false)
      })
      .catch(err => {
        setDisabledBtn(false)
        setRrrMsg(err)
        console.log("err", err)
      })
  }
  return (
    <>
      <Button
        variant="link"
        className={`${anim(1)} btn-back-help ai-arrow-from-left mb-3`}
        link={"/"}
      >
        Kembali
      </Button>
      <div className="login-register" ref={trigger}>
        <h2 className={`${anim(1)}`}>Masuk atau Daftar</h2>
        <p className={`${anim(2)}`}>Masukkan nomor HP kamu untuk melanjutkan</p>
        <Formik
          initialValues={initialRegisterValues}
          validationSchema={YupRegisterValidation}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group col-12">
                  <Input
                    floatingLabel
                    variant={"underline"}
                    label={"No. Hp"}
                    as="input"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleChange}
                    touched={touched}
                    errors={errors}
                    className={`${anim(3)}`}
                  />
                </div>
                <div className="form-group col-12">
                  <Button
                    variant="primary"
                    disabled={disabledBtn}
                    className={`btn-block mt-4 ${anim(4)}`}
                    type="submit"
                  >
                    {disabledBtn ? (
                      <img src={circle} className="img-fluid loading" />
                    ) : (
                      "Lanjut"
                    )}
                  </Button>
                  <div className="invalid-feedback">{errMsg}</div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}
