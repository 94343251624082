import React, { useState, useEffect } from "react"
import { parseCookies } from 'nookies'
import { navigate } from "gatsby"

import Layout from "components/layout"

import Seo from "components/seo"
import { LoginPhone } from "components/pages/login/login-phone"
import { LoginOtp } from "components/pages/login/login-otp"


const Login = () => {
    const cookies = parseCookies()

    //otp state
    const [otpState, setOtpState] = useState(false)
    const [currentNumber, setCurrentNumber] = useState("")
    const [timeLimit, setTimeLimit] = useState(30)


    useEffect(() => {
        if (timeLimit > 0 && otpState) {
            setTimeout(() => {
                setTimeLimit(timeLimit - 1)
            }, 1000)
        } else if (!otpState) {
            setTimeLimit(30)
        }
    }, [timeLimit, otpState])

    useEffect(() => {
        const authToken = cookies.authToken
        if (authToken) {
            // navigate("/login")
            navigate("/")
        }
    }, [])

    return (
        <Layout>
            <Seo title="Login" />
            <section className="py-main sc-login-login">
                <div className="container mw-sm">
                    {!otpState ? (
                        <LoginPhone
                            setCurrentNumber={setCurrentNumber}
                            setOtpState={setOtpState}
                        />
                    ) :
                        (
                            <LoginOtp
                                timeLimit={timeLimit}
                                currentNumber={currentNumber}
                                setOtpState={setOtpState}
                                setTimeLimit={setTimeLimit}
                            />
                        )}
                </div>
            </section>
        </Layout>
    )
}

export default Login