import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import OtpInput from "react-otp-input"
import { useMutation, useLazyQuery } from "@apollo/client"
import { setCookie } from "nookies"

import { useScrollAnim } from "src/components/hooks/hooks"
import { Button } from "components/anti/buttons/buttons"

import {
  checkPhoneNumberQuery,
  verifyOTPQuery,
  sendOTPQuery,
} from "components/utils/streamworx"

import circle from "assets/img/roll-animate.svg"

export const LoginOtp = ({
  timeLimit,
  setTimeLimit,
  currentNumber,
  setOtpState,
}) => {
  const [trigger, anim] = useScrollAnim()

  const [otpCode, setOtpCode] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [disabledBtn, setDisabledBtn] = useState(false)

  //apollo
  const [getVerifyOTP] = useMutation(verifyOTPQuery, {
    context: { clientName: "streamworx" },
  })
  const [submitOTP] = useMutation(sendOTPQuery, {
    context: { clientName: "streamworx" },
  })
  const [
    numberStatus,
    { data: numberStatusRes },
  ] = useLazyQuery(checkPhoneNumberQuery, {
    context: { clientName: "streamworx" },
  })

  const checkNumberStatus = async () => {
    const values = { phoneNumber: currentNumber }
    numberStatus({
      variables: { ...values },
    })
  }

  const handleSubmitOtp = () => {
    setErrMsg("")
    setDisabledBtn(true)
    const values = {
      phoneNumber: currentNumber,
      otp: otpCode,
    }
    getVerifyOTP({
      variables: { ...values },
    })
      .then(res => {
        const status = res.data.verifyOTP.status
        setDisabledBtn(false)

        if (status === "SUCCESS") {
          // Set
          setCookie(null, "phoneNumber", currentNumber, {
            maxAge: 12 * 60 * 60,
            path: "/",
          })
          checkNumberStatus()
        } else if (status === "FAILED") {
          setErrMsg("Kode yang anda masukkan salah")
        }
      })
      .catch(err => console.log("err", err))
  }

  const handleResendOTP = () => {
    const values = { phoneNumber: currentNumber }
    setTimeLimit(30)
    submitOTP({
      variables: { ...values },
    })
      .then(res => {})
      .catch(err => console.log("err", err))
  }

  useEffect(() => {
    if (numberStatusRes?.checkPhoneNumber) {
      const phoneStatus = numberStatusRes?.checkPhoneNumber
      if (phoneStatus === "NOT_REGISTERED") {
        navigate("/register")
      } else if (phoneStatus === "REGISTERED") {
        navigate("/login/verify")
      } else if (phoneStatus === "CREATE_PIN") {
        navigate("/register/create-pin")
      } else if (phoneStatus === "REGISTER_PAKDOK") {
        navigate("/register/pakdok")
      }
    }
  }, [numberStatusRes])

  return (
    <>
      <Button
        variant="link"
        className="btn-back-help ai-arrow-from-left mb-3"
        onClick={() => setOtpState(false)}
      >
        Kembali
      </Button>
      <div className={`otp `} ref={trigger}>
        <h2 className={`${anim(1)} `}>Kode Verifikasi</h2>
        <p className={`${anim(2)} `}>
          Masukkan 4 digit kode verifikasi yang telah dikirim melalui
          <span className="font-weight-bold"> SMS</span> ke nomor{" "}
          <span className="font-weight-bold">{currentNumber}</span>
        </p>
        <OtpInput
          value={otpCode}
          containerStyle="otp-container"
          inputStyle={`otp-input ${false ? "error" : ""}`}
          onChange={val => setOtpCode(val)}
          numInputs={4}
          isInputNum={true}
          className={`${anim(3)} `}
        />
        <div className={`my-3 w-100 ${anim(4)} `}>
          <Button
            className={`w-100`}
            disabled={timeLimit > 0}
            onClick={handleResendOTP}
          >
            <p className="small text-muted text-center">
              {timeLimit > 0
                ? `Kirim ulang dalam ${timeLimit} detik`
                : "Tekan untuk mengirim ulang kode"}
            </p>
          </Button>
        </div>
        <Button
          variant="primary"
          disabled={disabledBtn}
          className={`btn-block ${anim(5)} `}
          onClick={handleSubmitOtp}
        >
          {disabledBtn ? (
            <img src={circle} className="img-fluid loading" />
          ) : (
            "Lanjut"
          )}
        </Button>
        <div className="invalid-feedback">{errMsg}</div>
      </div>
    </>
  )
}
